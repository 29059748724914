import React from 'react'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
  } from '@chakra-ui/react'
import Navbar from '../layout/Navbar'

function Error404() {
  return (
    <div>
      <Navbar/>
        <Alert status='error'>
            <AlertIcon />
            <AlertTitle>Error 404</AlertTitle>
            <AlertDescription>This page was not found.</AlertDescription>
        </Alert>
    </div>
  )
}

export default Error404