import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Categories from "./pages/Categories";
import ProductDetail from "./pages/ProductDetail";
import Error404 from "./pages/Error404";
import Basket from "./pages/Basket";
import Product from "./pages/Product";
import HomePage from "./pages/HomePage";
import WaiterRequest from "./pages/WaiterRequest";
import Account from "./pages/Account";
import OrderConfirm from "./pages/OrderConfirm";
import OrderStatus from "./pages/OrderStatus";
import Review from "./pages/Review";
import About from "./pages/About";
import Signin from "./Auth/Signin";
import "./i18n";
import { useAuth } from "./store/AuthContext";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RequestSongFollow from "./pages/RequestSongFollow";

function App() {
  const { loggedIn } = useAuth();

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/categories" exact component={Categories} />
          <Route path="/product/:category_id" component={Product} />
          <Route
            path="/product_detail/:product_id"
            component={ProductDetail}
          />
          <Route path="/basket" component={Basket} />
          <Route path="/privacy_policy" component={PrivacyPolicy} />
          <Route path="/song_follow" component={RequestSongFollow} />
          <PrivateRoute path="/orderconfirm" component={OrderConfirm} />
          <PrivateRoute path="/waiter_calling" component={WaiterRequest} />
          <PrivateRoute path="/order_status" component={OrderStatus} />
          <PrivateRoute path="/rating" component={Review} />
          <Route path="/menu_id/:qrcode" component={Signin} />
          <Route path="/about" component={About} />
          <Route path="/error" render={() => <Redirect to="/" />} />
          <PrivateRoute path="/account" component={Account} />
          <PrivateRoute path="*" component={Error404} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
