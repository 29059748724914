import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Image, Text } from "@chakra-ui/react";
import { fetchFavoriteProducts } from "../api/api";
import { Link } from "react-router-dom";
import LoadingCircle from "./LoadingCircle";

const FavoriteProduct = () => {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await fetchFavoriteProducts();
        setProducts(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <LoadingCircle />;

  return (
    <>
      {products?.length > 0 && (
        <Text fontWeight="bold" color="teal" textAlign="center" fontSize="md">
          Favori Ürünlerimiz
        </Text>
      )}

      <Slider {...settings}>
        {products?.map((product) => (
          <Link key={product.id} to={`/product_detail/${product.id}`}>
            <Box
              bg="white"
              boxShadow="lg"
              rounded="lg"
              p={4}
              overflow="hidden"
              minH="150px"
              maxH="150px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                src={`${process.env.REACT_APP_BASE_ENDPOINT}/storage/${product.image}`}
                alt={product.name}
                objectFit="cover"
                height={50}
                borderRadius={5}
              />

              <Text
                mt="2"
                fontWeight="semibold"
                fontSize="lg"
                lineHeight="short"
                textAlign="center"
              >
                {product.title}
              </Text>
            </Box>
          </Link>
        ))}
      </Slider>
    </>
  );
};

export default FavoriteProduct;
