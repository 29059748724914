import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Text,
  Textarea,
  Grid,
  GridItem,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import NavbarBottom from "../layout/NavbarBottom";
import { Link, useHistory } from "react-router-dom";
import { accoundShow, postWaiter, settings } from "../api/api";
import { useTranslation } from "react-i18next";
import Navbar from "../layout/Navbar";

function WaiterRequest() {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState("");
  const [note, setNote] = useState("");
  const [restaurantName, setRestaurantName] = useState();

  const handleChangeInput = (e) => {
    setInput(e.target.value);
  };
  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };

  const waiterCall = async (e) => {
    onOpen();
    const data = {
      name: input,
      note: note,
    };
    await postWaiter(data);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await settings();
        setRestaurantName(res.restaurant_name);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [restaurantName]);


  const { t } = useTranslation();

  return (
    <Box mt={5}>
      <Navbar />
      <Link to="/">
        <Flex>
          <Box position="absolute" top="2" ml={2}>
            <i className="fas fa-angle-left yön"></i>
          </Box>
        </Flex>
      </Link>

      <Grid
        h="80%"
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(1, 1fr)"
        gap={10}
      >
        <Container>
          <Text
            mt={5}
            fontWeight="semibold"
            textAlign="center"
            fontSize={30}
            color="teal.500"
          >
            {t("Garson Talebi")}
          </Text>
          <GridItem mt={14} rowSpan={4}>
            <FormControl>
              <FormLabel color="teal">{t("İsim :")}</FormLabel>
              <Input
                placeholder={t("İsminizi Giriniz")}
                value={input}
                onChange={handleChangeInput}
              />

              <FormLabel color="teal" mt={4}>
                {t("Bir notunuz var mı:")}
              </FormLabel>
              <Textarea
                height="150px"
                value={note}
                onChange={handleChangeNote}
                placeholder={t("Notunuzu Giriniz")}
              />
              <Button
                onClick={waiterCall}
                color="white"
                mt={4}
                width="100%"
                bgGradient={theme.colors.ithinkGradient}
                _hover={{bgColor:theme.colors.ithinkGradient}}
                                                background={theme.colors.ithinkGradient}
              >
                {t("Garson Çağır")}
              </Button>
              <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{restaurantName && restaurantName}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody color="gray">
                    <Flex>
                      <Text color="black" fontWeight="semibold">
                        {t("İsim :")}
                      </Text>
                      <Text color="gray">{input}</Text>
                    </Flex>
                    <Flex>
                      <Text color="black" fontWeight="semibold">
                        {t("Not :")}
                      </Text>
                      <Text color="gray">{note}</Text>
                    </Flex>
                    {t(
                      "Garson talebiniz alınmıştır. En kısa süre içerisinde ilgilenilecektir."
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={onClose}>{t("Kapat")}</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </FormControl>
          </GridItem>
        </Container>
      </Grid>
      <NavbarBottom />
    </Box>
  );
}

export default WaiterRequest;
