import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
  Box,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useMenu } from "../store/MenuContext";
import notice from "../kalyan-cafe-notification/IMG_7837.png";

const NotificationModal = () => {
  const { showNotificationModal, setShowNotificationModal } = useMenu();
  useEffect(() => {
    if (showNotificationModal) {
      onOpen();
    }
  }, [showNotificationModal]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = () => {
    setShowNotificationModal(false);
    onClose();
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent style={{ background: 'none', border: 'none' }}>
          <ModalCloseButton
            color="black"
            style={{
              position: 'absolute',
              top: '-8px',
              right: '12px',
              fontSize: '16px',
              fontWeight: 'bold',
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '15px',
              cursor: 'pointer',
            }}
          />
          <ModalBody style={{ paddingInlineStart: 'auto', WebkitPaddingEnd: 'auto' }}>
            <Box display="flex" justifyContent="center">
              <Image
                width={450}
                height={500}
                src={notice}
                alt="menulogo"
                style={{ margin: '0 auto', display: 'block' }}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>


    </>
  );
};

export default NotificationModal;
