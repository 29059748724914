import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { BallTriangle } from "react-loader-spinner";

const Loading = ({ height }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height={height}
      zIndex={5}
    >
      <BallTriangle
        height={100}
        width={100}
        radius={5}
        color="#319795"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle=""
        visible={true}
      />
    </Flex>
  );
};

export default Loading;
