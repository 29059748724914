import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import NavbarBottom from "../layout/NavbarBottom";
import { useTranslation } from "react-i18next";
import "../css/star.css";
import { postReview, settings } from "../api/api";
import Navbar from "../layout/Navbar";

function Review() {
  const theme = useTheme();
  const [rating, setRating] = useState(0);
  const [note, setNote] = useState("");
  const [input, setInput] = useState("");
  const [hover, setHover] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [restaurantName, setRestaurantName] = useState();

  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };

  const handleChangeInput = (e) => {
    setInput(e.target.value);
  };

  const reviewPost = async (e) => {
    onOpen();
    const data = {
      name: input,
      note: note,
      rate: rating,
    };
    await postReview(data);
    setInput("");
    setRating(0);
    setNote("");
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await settings();
        setRestaurantName(res.restaurant_name);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [restaurantName]);

  return (
    <div>
      <Navbar />

      <Link to="/">
        <Flex>
          <Box position="absolute" top="2" ml={2}>
            <i className="fas fa-angle-left yön"></i>
          </Box>
        </Flex>
      </Link>
      <Text
        mb={5}
        mt={5}
        fontWeight="semibold"
        textAlign="center"
        fontSize={30}
        bgClip="text"
        color="teal"
      >
        {t("Bizi Değerlendirin")}
      </Text>

      <Container height="100vh">
        <Form>
          <Form.Group className="mb-3">
            <div className="container d-flex justify-content-center mt-100">
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body card-star text-center">
                      <div className="star-rating">
                        {[...Array(5)].map((star, index) => {
                          index += 1;
                          return (
                            <Button
                              type="Button"
                              key={index}
                              className={
                                index <= (hover || rating) ? "on" : "off"
                              }
                              onClick={() => setRating(index)}
                              onMouseEnter={() => setHover(index)}
                              onMouseLeave={() => setHover(rating)}
                            >
                              <span className="bx bxs-star"></span>
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
        </Form>
        <FormControl>
          <FormLabel color="teal" mt={4}>
            {t("Öneri ve Görüşleriniz için:")}
          </FormLabel>
          <Input
            placeholder={t("İsminizi giriniz")}
            value={input}
            onChange={handleChangeInput}
          />

          <Textarea
            mt={5}
            value={note}
            onChange={handleChangeNote}
            rows={5}
            placeholder={t("Yorumlarınız...")}
          />
        </FormControl>

        <Button
          onClick={reviewPost}
          color="white"
          mt={4}
          width="100%"
          bgGradient={theme.colors.ithinkGradient}
          _hover={{ bgColor: theme.colors.ithinkGradient }}
          background={theme.colors.ithinkGradient}
        >
          {t("Gönder")}
        </Button>
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{restaurantName && restaurantName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody color="gray">
              {t(
                "Öneri ve görüşleriniz restorana iletilmiştir. Teşekkür Ederiz..."
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>{t("Kapat")}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
      <NavbarBottom />
    </div>
  );
}

export default Review;
