import {
  Box,
  Container,
  Flex,
  Text,
  Grid,
  GridItem,
  Heading,
  useTheme,
} from "@chakra-ui/react";
import NavbarBottom from "../layout/NavbarBottom";
import { Link } from "react-router-dom";
import { useAuth } from "../store/AuthContext";
import Navbar from "../layout/Navbar";

function About() {
  const theme = useTheme();
  const { loggedIn } = useAuth();

  const instagramLink =
    "https://www.instagram.com/ithinkmenu/?igshid=MzRlODBiNWFlZA%3D%3D==";

  const websiteLink = "https://www.ithinkmenu.com/";

  const sendEmail = () => {
    window.location.href = "mailto:bilgi@ithinkmenu.com";
  };

  return (
    <>
      <Navbar />
      <Box mb={120} backgroundColor="white">
        <Link to="/">
          <Flex>
            <Box position="absolute" top="2" ml={2}>
              <i className="fas fa-angle-left yön"></i>
            </Box>
          </Flex>
        </Link>

        <Grid
          h="100%"
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(1, 1fr)"
          gap={10}
        >
          <Container>
            <GridItem mt={5} rowSpan={4}>
              <Box
                border="1px solid teal"
                p={4}
                borderRadius={20}
                bgGradient={theme.colors.ithinkGradient}
                _hover={{ bgColor: theme.colors.ithinkGradient }}
                background={theme.colors.ithinkGradient}
                mt={10}
              >
                <Heading
                  color="black"
                  bg="white"
                  borderRadius={20}
                  p={3}
                  textAlign="center"
                  as="h2"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  <Text color="teal">IThink Sipariş Sistemleri</Text>
                </Heading>
                <Text mt={3} fontSize="16px" color="white">
                  <Text display="inline" fontWeight="bold">
                    IThink
                  </Text>
                  , QR menülerin hazırlanması konusunda uzmanlaşmış bir
                  şirkettir. Müşterilerinize sunduğunuz yemek seçeneklerini daha
                  hızlı ve kolay bir şekilde sipariş etmelerine olanak tanıyan,
                  kullanımı kolay bir QR menü çözümü sunuyoruz.
                </Text>
              </Box>

              <Box
                mt={10}
                border="1px solid teal"
                p={4}
                borderRadius={20}
                bgGradient={theme.colors.ithinkGradient}
                _hover={{bgColor:theme.colors.ithinkGradient}}
                                                background={theme.colors.ithinkGradient}
              >
                <Heading
                  color="black"
                  bg="white"
                  borderRadius={20}
                  p={3}
                  textAlign="center"
                  as="h2"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  <Text color="teal">İletişim</Text>
                </Heading>
                <Text mt={3} fontSize="16px" color="white">
                  <Flex mt={2}>
                    <Box alignSelf="center">
                      <i className="fa-solid fa-2x fa-location-dot"></i>
                    </Box>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <p>
                      {" "}
                      Kayaşehir mahallesi , Kayaşehir bulvarı
                      Başakşehir/İstanbul
                    </p>
                  </Flex>
                  <br />
                  <Flex>
                    <Box alignSelf="center">
                      <i className="fa-solid fa-2x fa-phone"></i>
                    </Box>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <p>
                      0531 331 9481 <br />
                      0538 989 4163
                    </p>
                  </Flex>
                </Text>
                <Box mt={5} color="white">
                  <a href={instagramLink}>
                    <i className="fa-brands fa-2x fa-instagram"></i>
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a href={websiteLink}>
                    <i className="fa-brands fa-2x fa-safari"></i>
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="#" onClick={sendEmail}>
                    <i className="fa-solid fa-2x fa-envelope"></i>
                  </a>
                </Box>
              </Box>
            </GridItem>
          </Container>
        </Grid>

        <Text mt="30" fontSize="14px" color="#888" textAlign="center">
          Tüm hakları saklıdır. IThink Software Company &copy; 2023
        </Text>
        {loggedIn && <NavbarBottom />}
      </Box>
    </>
  );
}

export default About;
